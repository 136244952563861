import React from 'react'
import'../../App.css'
import Footer from '../Footer';



export default function Products() {
  return (
    <div>
    <h1 className='products'>PRODUCTS</h1>
    <Footer></Footer>
    </div>
  )
}

