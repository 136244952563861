import React from 'react'
import '../../App.css'

import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Cards from '../Cards';
import Bsgrid from '../Bsgrid';
function Home () {
  return (
    <div>
    

    <Bsgrid></Bsgrid>
<Footer></Footer>

    </div>
  )
}

export default Home;

