import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <div className='footer-container'>
    <section className='footer-subscription'>
  
    
    </section>
    <div className='footer-links'>

        <div className='footer-link-wrapper'>
<div className='footer-link-items'>
    
   <a target="_blank" href="https://www.1locale4technology.com/">Technology</a>
   <a target="_blank" href="https://www.1locale4interview.com/">Inteview</a>
   <a target="_blank" href="https://www.1locale4finance.com/">Finance</a>
   <a target="_blank" href="https://www.1locale4curiosity.com/">Knowledge</a>
</div>

        </div>
        </div>
    

    <section className='social-media'>
        <div className='social-media-wrap'>
            <div className='footer-logo'>
                <Link to='/' className='social-logo'>
                1L4 <i className='fa fa-location-arrow'></i>
                </Link>
            </div>
            
            <div className='social-icons'>
               
                  <div className='social-icon-link facebook'>   <i className=' fab fa-facebook-f'></i></div>
               
                <Link className='social-icon-link instagram' to='/' target='_blank' aria-label='Instagram'>
                    <i className=' fab fa-instagram'></i>
                </Link>
                <Link className='social-icon-link linkedin' to='/' target='_blank' aria-label='Facebook'>
                    <i className=' fab fa-linkedin'></i>
                </Link>
                 <Link className='social-icon-link youtube' to='/' target='_blank' aria-label='Instagram'>
                    <i className=' fab fa-youtube'></i>
                </Link>
             
            </div>
        </div>
    </section>
    </div>
  )
}

export default Footer