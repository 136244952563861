
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter , Route, Routes } from 'react-router-dom';
import Home from '../src/components/pages/Home';
import Services from '../src/components/pages/Services';
import Products from '../src/components/pages/Products';
import SignUp from '../src/components/pages/SignUp';
import Bsgrid from './components/Bsgrid';
function App() {
  return (
  <div className='App'>
    <BrowserRouter>

<Navbar></Navbar>

<Routes>
<Route path='/' element={<Home/>}> </Route>
<Route path='/services' element={<Services/>}> </Route>
<Route path='/products' element={<Products/>}> </Route>
<Route path='/sign-up' element={<SignUp/>}> </Route>

</Routes>


</BrowserRouter>
   
  </div>
  );
}

export default App;
