import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Bsgrid.css'
  
export default function Bsgrid() {
  return (
    <div className='wrapper'>
      
      <Row>
    <Col md={4}>
    <a target="_blank" href="https://www.1locale4interview.com/"> <div className='gridbox'>
          <h3>Interview</h3>
          <div className="line" style={{backgroundColor: "green"}}></div>
          <p>Prepare for various inteview topics ranging from Aptitude to HR questions</p>
          </div></a>
      </Col>
      <Col md={4}>
      <a target="_blank" href="https://www.1locale4technology.com/"> <div className='gridbox'>
          <h3>Technology</h3>
          <div className="line" style={{backgroundColor: "darkgrey"}}></div>
         <p>Prepare and Stay updated with the latest tech trends </p>
          </div></a>
      </Col>
      <Col md={4}>
      <a target="_blank" href="https://www.1locale4curiosity.com/"><div className='gridbox'>
          <h3>Knowledge</h3>
          <div className="line" style={{backgroundColor: "maroon"}}></div>
          <p> Read extensive articles on various topics to enrich your memory</p>
          </div></a>
      </Col>
      </Row>
       <br></br>
      <Row>
     
      <Col md={4}>
      <a target="_blank" href="https://www.1locale4finance.com/">  <div className='gridbox'>
          <h3>Finance</h3>
          <div className="line" style={{backgroundColor: "darkblue"}}></div>
          <p>Read articles to gain knowledge about Financial concepts</p>
          </div></a>
      </Col>
      
      </Row>
   
    
    </div>
  );
}
